import React, { useState, useEffect, createContext } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import "../index.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { setGlobalState, useGlobalState } from "../state";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getPhoneScores } from "../httpServices/services";
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import DrawerCustom from '../component/Drawer';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { ContactlessOutlined } from "@mui/icons-material";
import EditButtons from './EditButtons'

export default function SegmentationWFM() {
    const [lowScore_Test, setLowScore_Test] = useGlobalState('lowScore_Test');
    const [medScore_Test, setMedScore_Test] = useGlobalState('medScore_Test');
    const [highScore_Test, setHighScore_Test] = useGlobalState('highScore_Test');
    const [maxPriority_Low_Test, setMaxPriority_Low_Test] = useGlobalState('maxPriority_Low_Test');
    const [initialPriority_Low_Test, setInitialPriority_Low_Test] = useGlobalState('initialPriority_Low_Test');
    const [initialPriority_Medium_Test, setInitialPriority_Medium_Test] = useGlobalState('initialPriority_Medium_Test');
    const [phoneScoreBody, setPhoneScoreBody] = useGlobalState('phoneScoreBody')
    const [editMode, setEditMode] = useGlobalState('editMode')
    const [maxCallHoldingLower_Test, setMaxCallHoldingLower_Test] = useGlobalState('maxCallHoldingLower_Test');
    const [maxCallHoldingUpper_Test, setMaxCallHoldingUpper_Test] = useGlobalState('maxCallHoldingUpper_Test');
    const [midScoreThresholdTask_Test, setMidScoreThresholdTask_Test] = useGlobalState('midScoreThresholdTask_Test')
    const [open, setOpen] = React.useState(false);
    const [disableSave, setDisableSave] = useGlobalState('disableSave');
    const [midScoreGreatErr, setMidScoreGreatErr] = React.useState(false);
    const [highScoreLowErr, setHighScoreLowErr] = React.useState(false);
    const [midScoreLowErr, setMidScoreLowErr] = React.useState(false);
    const [maxPriErr, setMaxPriErr] = React.useState(false);
    const [zeroError, setZeroError] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        setHighScoreLowErr(false);
        setMidScoreGreatErr(false);
        setMidScoreLowErr(false)
        setMaxPriErr(false)
        setZeroError(false)
      };

    const wfmAlerts = async () => {

        if (maxCallHoldingUpper_Test < maxCallHoldingLower_Test) {
          setOpen(true);
          setDisableSave(true)
        } else if (maxCallHoldingUpper_Test > maxCallHoldingLower_Test) {
          setOpen(false);
          setDisableSave(false)
        }
    
        if (midScoreThresholdTask_Test > maxCallHoldingUpper_Test) {
          setMidScoreGreatErr(true)
          setDisableSave(true)
        } else if (maxCallHoldingUpper_Test < midScoreThresholdTask_Test) {
          setHighScoreLowErr(true)
          setDisableSave(true)
        }
    
        if (midScoreThresholdTask_Test < maxCallHoldingLower_Test) {
          setMidScoreLowErr(true)
          setDisableSave(true)
        }
    
        if (maxPriority_Low_Test < initialPriority_Low_Test) {
          setMaxPriErr(true)
          setDisableSave(true)
        }
      }

     //Manual Input Change Functions for Sliders
  const handleLowScoreInputChange = (event, newValue) => {
    console.log(event)
    phoneScoreBody.originalValues["ProgressionScore_Low"] = lowScore_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.team_assignment_scoring.low_threshold"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setLowScore_Test(event.target.value === '' ? '' : Number(event.target.value));
    let result = event.target.value.toString()
    if (result.substring(0,1) != "0"){
      setZeroError(true);
      setDisableSave(true);
    } else if (result.substring(0,1) == "0"){
      setZeroError(false);
      setDisableSave(false);
    } 
  };

  const handleMedScoreInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_Medium"] = medScore_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.team_assignment_scoring.medium_threshold"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setMedScore_Test(event.target.value === '' ? '' : Number(event.target.value));
    let result = event.target.value.toString()
    if (result.substring(0,1) != "0"){
      setZeroError(true);
      setDisableSave(true);
    } else if (result.substring(0,1) == "0"){
      setZeroError(false);
      setDisableSave(false);
    } 
  };

  const handleHighScoreInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_High"] = highScore_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.team_assignment_scoring.high_threshold"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setHighScore_Test(event.target.value === '' ? '' : Number(event.target.value));
    let result = event.target.value.toString()
    if (result.substring(0,1) != "0"){
      setZeroError(true);
      setDisableSave(true);
    } else if (result.substring(0,1) == "0"){
      setZeroError(false);
      setDisableSave(false);
    } 
  };

  const handleInitialPriLowInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["InitialPriority_Low"] = initialPriority_Low_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.priority_scoring.groups.low.initial_priority"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setInitialPriority_Low_Test(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleInitialPriMedInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["InitialPriority_Medium"] = initialPriority_Medium_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.priority_scoring.groups.medium.initial_priority"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setInitialPriority_Medium_Test(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleMaxPriorityInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["MaxPriority_Low"] = maxPriority_Low_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.priority_scoring.groups.low.max_priority"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setMaxPriority_Low_Test(event.target.value === '' ? '' : Number(event.target.value));
  };


  const lowScoreArray = []
  //Change functions for Sliders
  const handleLowScoreSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_Low"] = lowScore_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.team_assignment_scoring.low_threshold"] = newValue
    phoneScoreBody.updateFlag = true;
    setLowScore_Test(newValue);
  };

  const handleMedScoreSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_Medium"] = medScore_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.team_assignment_scoring.medium_threshold"] = newValue
    phoneScoreBody.updateFlag = true;
    setMedScore_Test(newValue);
  };

  const handleHighScoreSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_High"] = highScore_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.team_assignment_scoring.high_threshold"] = newValue
    phoneScoreBody.updateFlag = true;
    setHighScore_Test(newValue);
  };

  const handleInitialPriLowSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["InitialPriority_Low"] = initialPriority_Low_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.priority_scoring.groups.low.initial_priority"] = newValue
    phoneScoreBody.updateFlag = true;
    setInitialPriority_Low_Test(newValue);
  };

  const handleInitialPriMedSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["InitialPriority_Medium"] = initialPriority_Medium_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.priority_scoring.groups.medium.initial_priority"] = newValue
    phoneScoreBody.updateFlag = true;
    setInitialPriority_Medium_Test(newValue);
  };

  const handleMaxPrioritySliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["MaxPriority_Low"] = maxPriority_Low_Test
    phoneScoreBody.newValues["data.cec_sales_phone_scoring_test_group.priority_scoring.groups.low.max_priority"] = newValue
    phoneScoreBody.updateFlag = true;
    setMaxPriority_Low_Test(newValue);
  };


  const getImConfigData = async () => {
    let params = { name: "all" }
    let result = await axios.get('/api/az_func_im_config', { params });
    sessionStorage.setItem("ImConfigData", JSON.stringify(result.data))
    let data = sessionStorage.getItem("ImConfigData")
    setGlobalState("imConfigData", JSON.parse(data))
    if (result.data === null || result.data === "") {
      result = { data: "No record found with that name" }
    }
    return result
  }

    const setValues = async () => {
        let result = await getImConfigData()
        console.log(result.data)
        for (const i in result.data) {
          if (result.data[i].name == "inbound_voice_general") {
            setLowScore_Test(result.data[i].data.cec_sales_phone_scoring_test_group.team_assignment_scoring.low_threshold)
            setMedScore_Test(result.data[i].data.cec_sales_phone_scoring_test_group.team_assignment_scoring.medium_threshold)
            setHighScore_Test(result.data[i].data.cec_sales_phone_scoring_test_group.team_assignment_scoring.high_threshold)
            setInitialPriority_Low_Test(result.data[i].data.cec_sales_phone_scoring_test_group.priority_scoring.groups.low.initial_priority)
            setInitialPriority_Medium_Test(result.data[i].data.cec_sales_phone_scoring_test_group.priority_scoring.groups.medium.initial_priority)
            setMaxPriority_Low_Test(result.data[i].data.cec_sales_phone_scoring_test_group.priority_scoring.groups.low.max_priority)
          }
        }
      }

return (
    <Box component="main" sx={{ flexGrow: 1, marginTop:'1%'}}>
        <Typography variant="h5">Green Queue Control</Typography>
        <Divider sx={{ marginBottom: 2 }} />      
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={setValues} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="uiSubHeaderFont" id="accordionLabel">
              Segmentation Scores
            </Typography>
            <Tooltip
              title="These configs determine if the caller is high/medium/low priority group, and then sets the priority values for the call"
              sx={{ paddingTop: "0", color: '#febd12' }}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container xs={12} spacing={5}>
              <Grid item xs={6}>
                <Typography variant="h5">
                  Team Assignment Scores
                </Typography>
                <Divider sx={{ width: '70%', marginBottom: 2 }} />
                <Typography id="input-slider" gutterBottom>
                  Team Assignment Score Low: <Input
                    value= {lowScore_Test}
                    size="small"
                    onChange={handleLowScoreInputChange}
                    disabled={!editMode}
                    inputProps={{
                      step: 0.001,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                      fontColor: 'red'
                    }}
                  />
                </Typography>
                <Slider
                  value={lowScore_Test}
                  onChange={handleLowScoreSliderChange}
                  aria-labelledby="input-slider"
                  defaultValue={lowScore_Test}
                  disabled={!editMode}
                  step={.001}
                  min={0}
                  max={1}
                  size="small"
                />
                <Typography id="input-slider" gutterBottom>
                  Team Assignment Score Medium: <Input
                    value={medScore_Test}
                    size="small"
                    onChange={handleMedScoreInputChange}
                    disabled={!editMode}
                    inputProps={{
                      step: .001,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Typography>
                <Slider
                  value={medScore_Test}
                  onChange={handleMedScoreSliderChange}
                  aria-labelledby="input-slider"
                  defaultValue={medScore_Test}
                  disabled={!editMode}
                  step={.001}
                  min={0}
                  max={1}
                  size="small"
                />
                <Typography id="input-slider" gutterBottom>
                  Team Assignment Score High: <Input
                    value={highScore_Test}
                    size="small"
                    onChange={handleHighScoreInputChange}
                    disabled={!editMode}
                    inputProps={{
                      step: .001,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Typography>
                <Slider
                  value={highScore_Test}
                  onChange={handleHighScoreSliderChange}
                  aria-labelledby="input-slider"
                  defaultValue={highScore_Test}
                  disabled={!editMode}
                  step={.001}
                  min={0}
                  max={1}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">
                  Initial Priority Scores
                </Typography>
                <Divider sx={{ width: '70%', marginBottom: 2 }} />
                Initial Priority Low: <Input
                  value={initialPriority_Low_Test}
                  size="small"
                  onChange={handleInitialPriLowInputChange}
                  onClick={wfmAlerts}
                  disabled={!editMode}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                />
                <Slider
                  value={initialPriority_Low_Test}
                  onChange={handleInitialPriLowSliderChange}
                  onClick={wfmAlerts}
                  aria-labelledby="input-slider"
                  defaultValue={initialPriority_Low_Test}
                  disabled={!editMode}
                  step={1}
                  min={1}
                  max={100}
                  size="small"
                />
                <Typography id="input-slider" gutterBottom>
                  Initial Priority Medium: <Input
                    value={initialPriority_Medium_Test}
                    size="small"
                    onChange={handleInitialPriMedInputChange}
                    disabled={!editMode}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Typography>
                <Slider
                  value={initialPriority_Medium_Test}
                  onChange={handleInitialPriMedSliderChange}
                  aria-labelledby="input-slider"
                  defaultValue={initialPriority_Medium_Test}
                  disabled={!editMode}
                  step={1}
                  min={1}
                  max={100}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">
                  Max Priority Scores
                </Typography>
                <Divider sx={{ width: '70%', marginBottom: 2 }} />
                <Typography id="input-slider" gutterBottom>
                  Max Priority Low: <Input
                    value={maxPriority_Low_Test}
                    size="small"
                    onChange={handleMaxPriorityInputChange}
                    onClick={wfmAlerts}
                    disabled={!editMode}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Typography>

                <Slider
                  value={maxPriority_Low_Test}
                  onChange={handleMaxPrioritySliderChange}
                  onClick={wfmAlerts}
                  aria-labelledby="input-slider"
                  defaultValue={maxPriority_Low_Test}
                  disabled={!editMode}
                  step={1}
                  min={1}
                  max={100}
                  size="small"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>


        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Global High Configuration CAN NOT be lower than Global Low Configuration
          </Alert>
        </Snackbar>
        <Snackbar open={midScoreGreatErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Mid Score Threshold CAN NOT be GREATER than High Score Threshold
          </Alert>
        </Snackbar>
        <Snackbar open={highScoreLowErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            High Score Threshold CAN NOT be LOWER than High Score Threshold
          </Alert>
        </Snackbar>
        <Snackbar open={midScoreLowErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Mid Score Threshold CAN NOT be LOWER than Low Score Threshold
          </Alert>
        </Snackbar>
        <Snackbar open={maxPriErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Max Priority Low CAN NOT be lower than Initial Priority Low
          </Alert>
        </Snackbar>
        <Snackbar open={zeroError} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Manual Inputs must start with ZERO
          </Alert>
        </Snackbar>
    </Box>
)
}